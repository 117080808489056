var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('client-page', [_c('sub-visual', {
    attrs: {
      "tit": this.$route.query.code,
      "bg": "/images/sub/visual/sv--product.jpg"
    }
  }), _c('section', {
    staticClass: "section"
  }, [_c('v-container', [_c('v-row', _vm._l(_vm.programs, function (program, idx) {
    return _c('v-col', {
      key: idx,
      attrs: {
        "cols": "6",
        "md": "3"
      }
    }, [_c('card-primary', [_c('div', {
      staticClass: "thumb"
    }, [_c('div', {
      staticClass: "thumb__inner",
      staticStyle: {
        "background-image": "url(/images/test2.jpg)"
      }
    })]), _c('div', {
      staticClass: "w-100 pa-20 pa-md-30"
    }, [_c('h5', {
      staticClass: "tit-txt font-weight-bold line-height-13 word-keep-all"
    }, [_vm._v(" " + _vm._s(program.groups) + " ")])])])], 1);
  }), 1), _c('div', {
    staticClass: "v-pagination-wrap"
  }, [_c('v-pagination', {
    attrs: {
      "length": _vm.pageCount
    },
    on: {
      "input": _vm.search
    },
    model: {
      value: _vm.page,
      callback: function ($$v) {
        _vm.page = $$v;
      },
      expression: "page"
    }
  })], 1)], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }